@use '../Constants.scss';

.sports-page-wrap {
  background-color: #{Constants.$background-main};
  // padding-top: 10px;

  --inner-scroll {
    overflow: auto;
    position: relative;
  }
}

.sports-page {
  --ion-background-color: #{Constants.$background-main};
}

// .sports-page-wrap {
//     padding-top: 18px;
//     .foodfight-icon {
//         position: relative;
//         bottom: -5px;
//         margin-bottom: 10px;
//         right: 3.5px;
//     }
// }

// Unsure if this is intended to exist, but it affects the logo across the entire webpage
.foodfight-icon {
  max-height: 30px;
  max-width: 60px;
}

// body {
//   overflow: visible;
//   position: relative;
// }

// ion-content {
//   // overwrite inline styles
//   --offset-bottom: auto !important;
//   position: relative;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
