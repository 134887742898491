.new-message-popup {
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    --background: #E75432;
    color: white;
    padding: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --background-activated: #E75432;
    .new-message-popup-button {
      --background: #E75432;
      --background-hover: #E75432;
    }
  }
  
  .new-message-popup ion-icon {
    margin-left: 8px;
  }
  