@use '../Constants.scss';

.friends-list {
    .search-bar {
        --placeholder-font-style: normal;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;

        &.ios {
            padding-left: 16px;
            padding-right: 16px;
        }

        .searchbar-input, .searchbar-input.sc-ion-searchbar-ios {
            background-color: #{Constants.$background-friends-list-item};
            border: 2px solid #{Constants.$dark-gray};
            border-radius: 10px;

            &::placeholder {
                text-align: center;
                font-size: 1.2em;
            }
        }
    }
}