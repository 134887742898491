.chat-toolbar {
  --background: #e75432;
}

.chat-list {
  background: #faf7ec;
  display: flex;
  flex-direction: column;
}

.recent-chats-button {
  --background-activated: #e75432;
}

.chat-title {
  color: #fff;
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.message-row {
  display: flex;
  flex-direction: column;
  &.sent {
    align-items: flex-end;
  }
  &.received {
    align-items: flex-start;
  }
}

.message-item {
  padding: 0px 10px;
  margin: 8px 0px 0px 0px;
  max-width: 75%;
  align-items: center;
}

.message-item.sent {
  --background: #e2ffdb;
  color: #000;
  --border-radius: 22px;
  max-height: fit-content;
}

.message-item.received {
  --background: #ffffff;
  color: #000;
  text-align: left;
  --border-radius: 22px;
  max-height: fit-content;
}

.edited-label {
  margin-bottom: 25px;
  font-size: 0.75rem;
  color: gray;
  text-align: left;
}

.message-item p {
  margin: 0;
  padding: 10px 0px;
  word-wrap: break-word;
}

.message-item .message-time {
  font-size: 0.8rem;
  color: gray;
  margin-left: 10px;
  margin-bottom: 0;
  white-space: nowrap;
}

.date-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.date-marker div {
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}
.message-buttons-edit-wrapper {
  .message-save-edit,
  .message-cancel-edit {
    --background: #e75432;
  }
}

.message-options-wrapper {
  padding: 0px;
  margin: 0px;
}
.message-options-wrapper.right {
  margin-right: auto;
  align-self: flex-end;
}

.message-options-wrapper.left {
  margin-left: auto;
  align-self: flex-end;
}

.three-dots-menu {
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --margin-top: 0px;
  --margin-bottom: 0px;
  margin: 0px;
  padding: 0px;
}

.chat-message-handling-section {
  .message-edit-button,
  .message-delete-button,
  .message-copy-button {
    --min-height: 0;
    --inner-border-width: 0;
  }
}
