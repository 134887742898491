@use '../Constants.scss';

.settings-modal {
    --height: auto;
    --ion-background-color: #{Constants.$white};
    --ion-toolbar-background: #{Constants.$background-title};
    --ion-toolbar-color: #{Constants.$text-on-dark};

    $border-radius: 1rem;
    --border-radius: #{$border-radius} #{$border-radius} 0 0;

    &.ios {
        --border-radius: #{$border-radius};
    }

    ion-header {
        ion-toolbar {
            ion-title {
                font-family: "Upheaval";
            }
        }
    }

    .settings-modal-content {
        padding: {
            left: 2em;
            right: 2em;
        }

        ion-item {
            --border-color: color-mix(in srgb, #{Constants.$neutral-gray}, #{Constants.$white});
            --min-height: 65px;

            &>* {
                --color: #{Constants.$text-on-light};
            }

            ion-label {
                font-family: "Upheaval";
                font-size: 1.25em;
                font-weight: 500;
            }
        }
    }
}
