@use '../Constants.scss';

.live-fight-card {
  --background: #{Constants.$white};
  --color: #{Constants.$text-on-light};
  --game-panel-height: 36px;
  --game-panel-skew: 40deg;

  ion-card-content {
    font-size: 1rem;
    padding: 1em;

    p {
      margin: 0;
      line-height: normal;
    }

    & > * {
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 0.5em;
      }
    }

    .lfc-summary {
      min-width: 10%;
      flex: 1 0 0;

      & > p {
        font-size: 0.8em;
      }

      .time-text {
        margin-top: 5px;
        font-size: 0.6em;
      }

      .game-panel > .above {
        font-size: 1em;
      }
    }

    .lfc-interactions {
      flex: 0 1 0;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      position: relative;
      // justify-content: space-between;
      align-items: center;
      padding-left: 0.5em;
      // padding-top: 1em;

      ion-button {
        width: var(--game-panel-height);
        height: var(--game-panel-height);
        min-height: var(--game-panel-height);
        --background: #{Constants.$btn-red-orange};
        --background-hover: #{Constants.$btn-next-ready};
        --background-activated: #{Constants.$btn-next-ready};
        margin: 0;

        $padding: 0.3em;
        --padding-start: #{$padding};
        --padding-end: #{$padding};
        --padding-top: #{$padding};
        --padding-bottom: #{$padding};
      }

      .sport {
        // width: 100%;
        // margin-bottom: 5px;
        position: absolute;
        // top: -1px;
        transform: translateY(calc(-100% - 2px));
        font-family: 'Upheaval';
        font-size: 12px;
        border: 1px solid #d9d9d9;
        border-radius: 7px;
        padding: 2px;
        background: #d9d9d9;
        color: #000;
      }

      p {
        font-size: 0.8em;
      }
    }

    .response-btn {
      height: 1.5em;
      margin: 0;

      &.accept {
        --background: #{Constants.$btn-next-ready};
      }

      &.reject {
        --background: #{Constants.$btn-next-disabled};
      }

      &:not(:last-of-type) {
        margin-right: 0.5em;
      }

      &::part(native) {
        font-size: 0.8em;
      }
    }
  }
  .life-fights-button-accept {
    --background: #{Constants.$btn-next-ready};
    --background-hover: #{Constants.$btn-red-orange};
    --background-activated: #{Constants.$btn-red-orange};
  }
  .life-fights-button-reject {
    --background: #a4a4a4;
    --background-hover: #{Constants.$dark-gray};
    --background-activated: #{Constants.$dark-gray};
  }
  .life-fights-button-reject,
  .life-fights-button-accept {
    --border-radius: 10px;
    text-transform: none;
    min-width: 120px;
  }
}

#profile-page .cols.flex-space-between {
  justify-content: space-between;
}
