@use '../../Constants.scss';

#pick-challenger-modal-page {
  height: 100%; // Ensure the modal page takes the full height
  overflow: hidden;

  #pick-challenger-container {
    background-color: #f4dfc7;
    margin: 1.5rem;
    border-radius: 10px;
    height: calc(100% - 4rem); // Adjust height considering margin
    display: flex;
    flex-direction: column;
    overflow: auto;
    .start {
      left: 0;
      position: absolute;
      width: 30px;
      height: 30px;
      padding-bottom: 5px;
    }

    .end {
      position: absolute;
      right: -10px;
    }

    .overlay {
      opacity: 0.1;
      pointer-events: none;
    }

    .overlay-text {
      position: absolute;
      z-index: 999;
      font-size: 25px;
      top: 35%;
      left: 20%;
      right: 20%;
    }

    #search-share-bar {
      width: 80%;
      // height: 10%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-family: 'Upheaval';

      margin: {
        left: auto;
        right: auto;
        top: 1rem;
      }

      #search-bar {
        --background: #{Constants.$white};
        --border-width: 1px;
        --border-style: solid;
        --border-color: #{Constants.$neutral-gray};
        --border-radius: 0.75rem;
        --inner-border-width: 0;
        font-family: 'Upheaval';
      }

      .loadingList {
        display: 'flex';
        flex-wrap: 'wrap';
        width: '85%';
        justify-content: 'space-around';
        margin-left: 'auto';
        margin-right: 'auto';
      }

      //   #share-label {
      //     align-self: flex-end;
      //     display: flex;
      //     flex-direction: row;
      //     align-items: center;

      //     ion-icon {
      //       cursor: pointer;
      //     }

      //     p {
      //       margin: 0;
      //       margin-right: 0.5rem;
      //     }
      //   }

      //   ion-icon {
      //     padding: 0.25rem;
      //     background: Constants.$btn-red-orange;
      //     border-radius: 50%;
      //     color: Constants.$white;
      //   }
    }

    #pick-challenger {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;

      margin: {
        left: auto;
        right: auto;
      }

      #fighter-grid-scroll-container {
        width: 100%;
        // height: 60%;
        flex-wrap: nowrap;
        overflow-x: scroll !important;
        overflow-y: hidden;
        margin: {
          left: auto;
          right: auto;
          top: 1rem;
        }
        #fighter-grid {
          display: grid; // Ensure grid items are in a single row
          grid-auto-flow: row;
          overflow-x: scroll !important;
          overflow-y: hidden;
          width: max-content;
          --ion-grid-row: 1;

          .fighter-tile {
            width: max-content;
          }
        }
      }

      #grid-divider {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid Constants.$medium-gray;
        margin: 1em 1em 0 1em;
        padding: 0;
      }

      #topRival {
        font-family: 'Upheaval';
        text-align: center;
        margin-top: 0.5em;
        color: Constants.$text-on-light;
      }

      #top-rival-grid-scroll-container {
        width: 100%;
        // height: 65%;
        flex-wrap: nowrap;
        overflow-x: scroll !important;
        overflow-y: hidden;
        margin: {
          left: auto;
          right: auto;
        }
        #top-rival-grid {
          display: grid; // Ensure grid items are in a single row
          grid-auto-flow: row;
          overflow-x: scroll !important;
          overflow-y: hidden;
          width: max-content;
          --ion-grid-row: 1;

          .fighter-tile {
            width: max-content;
          }
        }
      }

      #match-challenger {
        // height: 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 10px;

        p {
          font-size: 0.5rem;
        }

        .match-challenger-button {
          min-height: 60px;
        }

        @media only screen and (max-height: 700px) {
          .match-challenger-button {
            min-height: 30px;
          }
        }

        #match-challenger-btn {
          --border-radius: 0.5rem;
          --background: #{Constants.$yellow};
          --background-activated: #{Constants.$orange};
          color: Constants.$text-on-light;
          --padding-start: 1.5rem;
          --padding-end: 1.5rem;
          margin: 0 3rem 1rem 3rem;

          &.checked {
            --background: #{Constants.$btn-red-orange};
            
            .match-challenger-button__text {
              color: #{Constants.$white};
            }
          }

          .match-challenger-button__text {
            display: flex;
            text-transform: none;
            flex-direction: column;
            text-align: left;
            font-weight: 400;
            right: 0;

            .title {
              font-family: 'Upheaval';
              font-size: 17px;
              margin: 0;
              padding-bottom: 3px;
              text-align: center;
            }

            .content {
              font-size: 12px;
              padding-left: 8px;
              padding-top: 8px;
              margin: 0;
            }
          }

          ion-checkbox {
            --background: #{Constants.$orange};
            color: Constants.$orange;
            --checkbox-background-checked: #{Constants.$orange};
            opacity: 1;
            --border-color-checked: #eb5529;

            &::part(container) {
              padding: 1px;
            }
          }
        }
      }

      #refer-friend {
        height: 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 3rem;

        p {
          font-size: 0.5rem;
        }

        .refer-friend-button {
          min-height: 60px;
        }

        @media only screen and (max-height: 700px) {
          .refer-friend-button {
            min-height: 30px;
          }
        }

        #refer-friend-btn {
          --border-radius: 0.5rem;
          --background: #{Constants.$yellow};
          --background-activated: #{Constants.$orange};
          color: Constants.$text-on-light;
          --padding-start: 1.5rem;
          --padding-end: 1.5rem;
          margin: 0.5rem 3rem;

          .refer-friend-text {
            display: flex;
            text-transform: none;
            flex-direction: column;
            text-align: left;
            font-weight: 400;

            .title {
              font-family: 'Upheaval';
              font-size: 17px;
              margin: 0;
              padding-bottom: 3px;
              text-align: center;
            }
          }
        }
      }
      @media only screen and (max-height: 800px) {
        #refer-friend {
          margin-top: 0;
        }
      }
    }
  }
  @media only screen and (max-height: 700px) {
    #pick-challenger-container {
      margin: 0;
      height: 100%;
      border-radius: 0px;
    }
  }
}
// styles for disablind delivery for normal usage
#fighter-grid.disabled {
  /* Example styles */
  opacity: 0.1;
  pointer-events: none; /* Prevents clicking on elements */
}

.overlay-message {
  position: absolute;
  margin: 0 auto;
  top: 45%;
  font-size: 25px;
  margin-left: -6%;
}

.skeletal-fighter-tile {
  height: 88.9px;
  width: 98px;
}

@media only screen and (max-height: 750px) {
  .skeletal-fighter-tile {
    height: 70.5px;
  }
}

@media only screen and (max-height: 600px) {
  .skeletal-fighter-tile {
    height: 59.1px;
  }
}