@use '../Constants.scss';

.entry-field {
    --background: #{Constants.$white};
    border-color: Constants.$black;
    --border-color: #{Constants.$black} !important;
    --placeholder-opacity: 0.35;
    text-align: left;

    // provides the rounded border for ios mode. values pulled from the md mode
    label.sc-ion-input-ios {
        --border-radius: 24px;
        --padding-start: 32px;
        --padding-end: 32px;
        border-width: 1px;
        border-style: solid;
    }
}
