@use '../Constants.scss';

  .row {
    display: flex;
    justify-content: space-around;
  }

  .row.profile-overview {
    display: grid;
    grid-template-columns: auto minmax(max-content, 170px);
    column-gap: min(50px, 10vw);
    justify-content: center;
    align-items: center;
    font-family: "Orbitron";
    font-weight: 500;
  }

  .profile-interactions {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > *:last-child:nth-child(odd) {
      position: relative;
      left: 50%;
    }

    .profile-button {
      max-width: min(70px, 22vw);
      aspect-ratio: 1/1;
      --background: #{Constants.$btn-red-orange};
      --background-hover: #{Constants.$white};
      --background-hover-opacity: 0.8;
      --background-focused: #{Constants.$white};
      --background-focused-opacity: 0.24;
      --background-activated: #{Constants.$white};
      --background-activated-opacity: 0.24;
      font-family: "Orbitron";
      text-transform: none;
      font-weight: 500;
      font-size: 0.7em;

      &.message-button {
        --background: #{Constants.$gloves-yellow};
      }

      &.friends-button {
        --background: #{Constants.$dark-gray-2};
      }

      &.follow-button-true {
        --background: #{Constants.$win-green};
      }

      .profile-button-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-template-rows: 0.7em 1fr;
        justify-items: center;
        row-gap: 5px;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

        > *:last-child {
          margin: auto;
        }

        .invisible-text {
          visibility: hidden;
        }

        .follow-button-content {
          visibility: visible;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .friends-info-container {
    justify-content: space-between;
    padding: 2px 5px;
    width: min(310px, 90%);
    margin: 10px auto;
    background-color: Constants.$background-profile-list;
    border-radius: 15px;
    border: 3px solid #{Constants.$black};
    font-family: "Orbitron";
    font-weight: 500;

    .profile-interactions .profile-button {
      max-width: min(55px, 22vw);
      font-size: 0.6em;

      .friends-amount {
        font-size: 1.4em;
      }

      .follow-button-content {
        font-size: 0.9em;
      }
    }
  }

  .friends-count {
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #FFE000 50%, #FFFFFF 50%);
    font-weight: bold;
    .col:first-child {
      padding-left: 10px;
      padding-right: 20px;
      background: none;
      font-weight: 700;
      font-size: 16px;
    }
    .col:last-child {
      padding-left: 20px;
      padding-right: 10px;
    }
    .friends-count-title {
      padding-bottom: 12px;
      padding-top: 5px;
    }
  }

  .friends-img-container {
    .friends-img {
      border-radius: 8px;
      min-height: 85px;
      min-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-top: 15px;
    }
    .friends-img:first-child {
      margin-right: 10px;
    }
    .venmo-name, .instagram-name {
      padding-top: 5px;
    }
  }

  .profile-picture {
    max-width: 120px;

    &.is-self-true {
      grid-column: span 2;
      margin: auto;
    }
  }

  .profile-name {
    margin-top: 5px;
    margin-bottom: 0;
    // font-family: "Upheaval";
    font-size: 1.4em;
    text-decoration: underline;
  }

  // .instagram-pic, .venmo-pic {
  //   max-width: 1.6rem;
  // }

  .friend-buttons {
    margin-top: 30px;
    .friend-button {
      --background: #ff451a;
      text-transform: none;
      --border-radius: 8px;
    }
  }

  .friend-fight-button {
    display: flex;
    .glove-icon {
      padding-left: 5px;
      max-width: 1.6rem;
    }
  }
  
  // Removing this rule would affect the my fights page, which I didn't want to refactor
  #profile-info-text {
    display: none;
  }

  // Used an id to avoid removing the above rule
  .friend-profile-page #profile-info-text {
    display: block;
  }

  .search-list {
    // top: 8%;
    --ion-background-color: #faf7ec;

  }