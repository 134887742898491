.venue-modal {
  --ion-background-color: #E75432;
  text-align: center;
}

.venue-modal-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20px;
}

.venue-modal-image {
  margin: 0px 0 10px 0;
  width: 160px;

  @media (min-width: 768px) {
    width: 200px;
  }

  @media (min-width: 1200px) {
    width: 250px;
  }
}

/* Title */
.venue-modal-title {
  margin: 0px 0 10px 0;
  font-family: "Upheaval";
  font-size: 72px;
  line-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-line {
  display: block;
}

@media (min-width: 768px) {
  .venue-modal-title {
    font-size: 96px;
    line-height: 80px;
  }
}

@media (min-width: 1200px) {
  .venue-modal-title {
    font-size: 120px;
    line-height: 100px;
  }
}

.venue-modal-description {
  font-size: 20px;
  line-height: 30px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (min-width: 1200px) {
    font-size: 28px;
    line-height: 42px;
  }
}

.venue-modal-button-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  font-weight: 600;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1200px) {
    padding: 0 120px;
  }
}

.venue-modal-button {
  font-family: "Upheaval";
  height: 52px;
  font-weight: bold;
  font-size: 24px;
  margin: 10px 0;

  @media (min-width: 768px) {
    height: 60px; 
    font-size: 28px;
  }

  @media (min-width: 1200px) {
    height: 70px;
    font-size: 32px;
  }
}

.delivery-button {
  --color: rgb(24, 24, 24);
  --background: #FFE006;
  --border-color: #ffffff;
  --border-style: solid;
  --border-width: 2px;
  --border-radius: 14px;
  --background-hover: #ff5500;
  --background-activated: #ff5500;
  --background-focused: #ff5500;
}

.venue-button {
  --color: rgb(24, 24, 24);
  --background: #ffffff;
  --border-color: #aaaaaa;
  --border-style: solid;
  --border-width: 2px;
  --border-radius: 14px;
  --background-hover: #ff5500;
  --background-activated: #ff5500;
  --background-focused: #ff5500;
}
