@use '../Constants.scss';

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.img-modal {
    justify-content: center; 
    align-items: center; 
    height: auto;
    --ion-background-color: #FFFEF9;
    --ion-toolbar-background: #E75432;
    --ion-toolbar-color: #E75432;
    --ion-toolbar-border-style: none;
    --border-radius: 0 0 1rem 1rem;

    &.show-modal {
        animation: slideInDown 0.5s forwards;
    }

    .toolbar-container {
        justify-content: center;
    }

    .img-modal__image {
        width: 90%;
        display: flex;
        margin: 0 auto;
        // padding-top: 30px;
        padding-top: calc(30px + var(--ion-safe-area-top));
        border-radius: 8px;
    }

    .img-modal__content {
        color: #5E5E5E;
        padding: 0 5%;
        &__header {
            font-size: 22px;
            font-style: normal;
            font-weight: 790;
            line-height: 17px;
            letter-spacing: -0.4px;
        }
    }
}

.toolbar-container {
    justify-content: center;
    .toolbar-content {
        display: flex;
        justify-content: center;
    }
}

.img-modal__toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-modal__button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E75432;
        font-size: 20px;
        margin: 0 auto;
        color: #FFFFFF;
    }
}
