@use '../Constants.scss';

.tab-view {
    .tab-view-tab-bar {
        display: flex;
        flex-direction: row;

        .tab-view-tab-btn {
            flex: 1 1 0;
            cursor: pointer;
            font-family: 'Upheaval';
            cursor: pointer;
            font-size: 5.5vw;

            margin: {
                left: 0.5rem;
                right: 0.5rem;
            }

            &.active {
                border-bottom: Constants.$background-title 0.2rem solid;
            }
        }
    }

    .tab-view-active-view {
        width: 100%;
        height: 100%;
    }
}