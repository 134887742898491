@use '../Constants.scss';

.rest-modal {
    --height: auto;
    --ion-background-color: #{Constants.$background-rest-modal};
    --ion-toolbar-background: #{Constants.$background-title};
    --ion-toolbar-color: #{Constants.$text-on-dark};
    // backdrop-filter: blur(1px); // TODO: do we want a blur?

    $border-radius: 1rem;
    --border-radius: #{$border-radius} #{$border-radius} 0 0;

    &.ios {
        --border-radius: #{$border-radius};
    }

    ion-header {
        // TODO: button needs to have background
        text-align: center;
    }

    .rest-modal-content {
        img {
            height: 10em;
            margin: auto;
            display: flex;
        }
        .inputs-wrapper {
            display: flex;
            flex-wrap: wrap;
            .rest-modal-input {
                border: 1px solid #{Constants.$neutral-gray};
                border-radius: 4rem;
                width: auto;
                height: auto;
                padding: 5px 14px;
                margin-right: 2px;
                margin-bottom: 3px;
                width: 32%;
                text-align: center;
                color: #000;
            }
            .rest-modal-input:first-child {
                width: 64%;
            }
        }

        .rest-modal-address {
            width: 100%;
            border: 1px solid #{Constants.$neutral-gray};
            border-radius: 4rem;
            padding: 0.5em;
            color: Constants.$btn-red-orange;
            background-color: Constants.$white;
            text-align: center;
            p {
                font-family: sans-serif;
            }
            

            margin: {
                top: 1rem;
                bottom: 1rem;
            }

            ;

            p {
                margin: 0;
            }
        }

        .rest-modal-rating {
            display: flex;
            align-items: center;

            ion-icon {
                width: 1.75em;
                height: 1.75em;
                color: #FFE000; // TODO: needs constant
                margin-right: 0.5em;
            }

            p {
                color: Constants.$text-on-light;
                font-size: 1.25em;
            }
        }
    }

    .rest-modal-footer {
        border-top: 1px solid #{Constants.$neutral-gray};

        ion-button {
            --background: #{Constants.$btn-next-ready};
            color: #{Constants.$text-on-dark};
        }
    }
    .cols {
        div {
            p {
                font-family: sans-serif;
            }
        }
    }
}
