@use '../Constants.scss';

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 48px;

  >div:nth-child(1) {
    margin-bottom: 8px;
  }

  .manual-label-title{
    margin: 12px 0 6px 0;
    text-align: left;
    width: 100%;
    font-weight: 600;
    color: #{Constants.$dark-gray};
  }
  .address-field-input {
    border: 1px solid #{Constants.$neutral-gray};
    border-radius: 10px;
    width: auto;
    height: auto;
    padding: 14px 18px;
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
    color: #000;
  }

  .autocomplete-input-form{
    text-align: center;
  }
  
  .address-field-input.autocomplete-input {
    height: 100%;
    width: 100%;
    border: 1px solid #{Constants.$neutral-gray};
    border-radius: 10px;
  }

  .rest-modal-input:first-child {
    width: 64%;
  }

  .toggle-autocomplete-text-container {
    margin-top: 16px;
    width: 100%;
    font-size: 1em;
    background-color: #{Constants.$light_gray_4};
    border: 2px solid  #{Constants.$light_gray_3};
    // box-shadow: 2px 5px 5px #{Constants.$light_gray_3};
    border-radius: 14px;
  }
  .toggle-autocomplete-text-container:hover, .toggle-autocomplete-text-container:active{
    background-color: #{Constants.$medium-gray};
    color: white;
  }

  .show-autocomplete-text-container {
    margin-bottom: 8px;
  }

  .toggle-autocomplete-text {
    cursor: pointer;
    margin: 22px auto;
    font-size: 16px;
    line-height: 6px;
    display: table;
    background: none;
  }

  .toggle-past-addr-text {
    margin-top: 8px;
  }

  >div:nth-child(1) {
    width: 100%;
  }
  .manual-state-zip{
    display: flex;
    gap: 14px;
    .manual-state, .manual-zip{
      display: flex;
      flex-direction: column;
      font-size: 16px;
    }
  }

  .past-address {
    display: flex;
    --background: #{Constants.$background-past-address};
    --background-hover: #{Constants.$white};
    --background-hover-opacity: 0.8;
    --background-focused: #{Constants.$white};
    --background-focused-opacity: 0.24;
    --background-activated: #{Constants.$white};
    --background-activated-opacity: 0.24;
    color: #{Constants.$dark_gray_2};
    --box-shadow: 0px 5px 5px 0px #00000040;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    font-size: 0.8em;
    font-family: "Orbitron";
    font-weight: 500;
    // max-width: 10rem;
    padding: 8px 5%;
    margin: 0;
    width: 100%;
  }
}
