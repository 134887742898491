@use '../../Constants.scss';

.item-card {
    --background: #{Constants.$background-card};
    margin-top: .7em;
    margin-bottom: .7em;

    ion-card-content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;

        .item-card-info {
            flex: 1 0 0;

            p {
                color: Constants.$text-on-light;
                margin: 0;
            }

        }

        .price{
            flex: 0 1 0;
            p {
                color: Constants.$text-on-light;
                margin: 0;
            }
        }

        ion-item {
            width: fit-content;
            --background: none;
            --border-style: none;
            --padding-start: 0;
            --inner-padding-end: 0;

            .amount-input {
                width: 2.5em;
                min-height: 2.5em;
                text-align: center;
                --background: #{Constants.$white} !important; // important flag prevents focus and hover from changing color
                --border-style: none;
                --highlight-color-focused: none;
                margin: 0;

                .input-wrapper {
                    border-radius: 0.5rem !important;
                    padding: 0;
                }


                .native-input {
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    // firefox compatibility
                    -moz-appearance: textfield;
                    appearance: textfield;
                }
            }

            ion-button {
                --color: #{Constants.$text-on-light};
                margin: 0;
            }
        }
    }
}