@use '../Constants.scss';

$tile-box-shadow:
  rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
  rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
  rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

.rest-tile {
  box-shadow: $tile-box-shadow;
  background-color: Constants.$background-rest-card;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  color: Constants.$text-on-light;
  font-size: 0.5rem;
  padding: 1em;
  cursor: pointer;

  * {
    margin: 0;
    overflow: hidden;
  }

  img {
    display: block;
    object-fit: scale-down;
    height: 10em;
    margin: {
      top: 0;
      left: auto;
      right: auto;
      bottom: 4px;
    };
  }

  h1 {
    line-height: 1;
    font-size: 1.5em;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    font-size: 1em;
  }

  .rest-rating {
    display: flex;
    align-items: center;
    font-size: 1.5em;

    p {
      position: relative;
      top: 0.075em;
    }
  }

  .rest-tile-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: Constants.$background-title;
    border-bottom-left-radius: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: {
      top: 0.25rem;
      bottom: 0.5em;
      left: 0.5em;
      right: 0.25rem;
    }

    .rest-tile-badge-pfps {
      height: 2em;
      align-self: flex-end;

      img {
        position: relative;
        height: 100%;

        &:first-child {
          z-index: 1;
        }

        &:not(:first-child) {
          margin-left: -1em;
        }
      }
    }

    .rest-tile-badge-overflow {
      border-radius: 50%;
      background-color: Constants.$badge-rest-accent;
      color: Constants.$text-on-dark;
      font-size: 1.5em;
      padding: 0.25em;
      margin-bottom: 0.15em;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.rest-skeleton-tile {
  width: 100%;
  box-shadow: $tile-box-shadow;
  border-radius: 1rem;
  font-size: 0.5rem;
  padding: 1em;

  & > ion-skeleton-text {
    border-radius: 4px;
  }
}

.selected-tile {
  background-color: Constants.$background-selected-card;
  color: Constants.$text-on-dark
}
