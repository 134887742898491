@use '../Constants.scss';

.forgot-password {
  position: relative;
  text-decoration-line: underline;
  text-decoration-thickness: 0.4px;
  text-underline-offset: 4px;
  float: center;
  margin: 16px;
  font-size: 16px;
  color: #{Constants.$dark_gray_2};
  cursor: pointer;
  font-family: 'Rubik';
  margin-bottom: 48px;
}

#signin-page {
  --background: #{Constants.$background-main};
  text-align: center;
  font-family: 'Upheaval';
  .tagline-text {
    color: rgb(43, 43, 43);
    font-size: 20px;
    font-family: 'Rubik';
    font-weight: 1200;
    line-height: calc(20 * 1px);
  }
  .challenge-text {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Rubik';
  }

  #signin-content {
    margin-top: 8.5vh;
    .dancing-pizza {
      width: 24vw;
      margin-bottom: 32px;
    }
    #logo-imgs {
      display: flex;
      justify-content: center;
      align-items: center;

      #logo-icon {
        height: 10vh;
        margin-right: 0.5rem;
      }

      #logo-text {
        margin-bottom: -10px;
        width: 100%;
        padding: 0 36px;
      }
    }

    p {
      margin-top: 3vh;
      font-weight: 500;
    }

    #entry-options {
      width: 75%;
      margin-top: 6vh;
      margin: {
        left: auto;
        right: auto;
      }

      .login-label {
        font-family: 'Rubik';
        display: flex;
        color: #{Constants.$gray};
        font-size: 16px;
        margin-top: 18px;
        margin-bottom: 18px;
      }

      .signin-btn {
        font-family: 'Upheaval';
        color: #{Constants.$white};
        --background: #{Constants.$btn-red-orange};
        --border-radius: 14px;
        --border-width: 2px;
        --border-color: #{Constants.$gray};
        --background: #{Constants.$btn-red-orange};
        --background-hover: #{Constants.$btn-red-orange};
        --background-activated: #{Constants.$btn-red-orange};
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        .button-native {
          --border-color: #{Constants.$gray};
        }
      }

      #signup-btn {
        font-family: 'Upheaval';
        --background: #{Constants.$light_gray};
        --background-color: #{Constants.$light_gray};
        --background-hover: #{Constants.$gloves_yellow};
        --background-activated: #{Constants.$gloves_yellow};
        --border-radius: 15px;
        --color: #{Constants.$white};
        --border-color: #{Constants.$gray};
        --border-radius: 14px;
        --border-width: 2px;
        margin-top: 2vh;
        width: 100%;
        // .button-native  {
        //     --border-color: red;
        // }
      }

      native-input sc-ion-input-ios {
        background-color: #{Constants.$white};
      }

      .entry-field,
      .entry-field label {
        --border-radius: 12px;
        --border-color: #{Constants.$gray};
        --border-width: 2px;
        background-color: #{Constants.$white};
        font-size: 16px;
        margin-bottom: 1.25vh;
        text-align: center;
      }

      .email {
        margin-bottom: 4.5vh;
      }
    }
  }
  .term-condition-wrapper {
    position: relative;
    margin: auto;
    margin-bottom: 50px;
    float: center;
    left: 0;
    bottom: -10px;
  }
  .condition-text {
    font-family: 'Rubik';
    margin: auto;
    font-size: 12px;
    max-width: 340px;
    float: center;
    line-height: calc(14 * 1.25px);
  }
  .term-condition-links {
    font-family: 'Rubik';
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-top: 4px;
    gap: 4px;
    a {
      color: #{Constants.$black};
      font-weight: bolder;
    }
  }
}
