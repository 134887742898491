@use '../Constants.scss';
.chat-modal {
  --height: 80%;
  --max-height: 80%;
  --top: auto;
  --bottom: 0;
  align-items: end;
  --border-radius: 1rem 1rem 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);

  ion-page {
    --background: #{Constants.$background-main};
  }

  ion-toolbar {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    --background: #{Constants.$orange_2};

    .newChatTitle {
      font-size: 1.2rem;
      color: #{Constants.$white};
    }
  }

  ion-content {
    --background: #{Constants.$background-main};
  }

  .chat-search-bar-container {
    margin: 10px;
    padding: 5px;

    .chat-search-bar {
      border-radius: 15px;
    }
  }
}
