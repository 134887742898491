@use '../Constants.scss';

.notification-container {
  position: relative;
}

.notification {
  position: relative;
}

.badge {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 10; // Ensure it overlays the toolbar
}

.user-search-content {
  position: absolute;
  --background: #{Constants.$background-main};

  &::part(scroll) {
    position: static;
    max-height: 320px;
  }

  &::part(background) {
    position: static;
  }

  .scroll-content {
    min-height: 0;
    height: 2em;
    --ion-background-color: #{Constants.$background-main};
    background: #{Constants.$background-main};

    .infinite-loading, .infinite-loading-text {
      margin: 0;
    }
  }
}
