@use '../../Constants.scss';

#fight-summary-modal-page {
  &::part(scroll) {
    display: flex;
    flex-direction: column;
  }

  .section-header {
    text-align: center;
    font-family: 'Upheaval';
    font-size: 1.5rem;
  }

  #game-section {
    width: 100%;
    margin-top: 0;

    --game-panel-gap-width: 1%;
    --game-panel-skew: 20deg;
    --game-panel-height: 75px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .game-panel .panel .gap {
      margin-left: 25px;
    }
  }

  #outcome-section {
    width: 100%;

    #outcome-info {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.75rem;
      color: Constants.$text-on-dark;
      background-color: Constants.$btn-red-orange;
      font-family: 'Upheaval';
      font-size: 1.3rem;
    }
  }

  #items-section {
    width: 100%;
    flex: 1 1 0;

    ion-list {
      background: none;

      .item-card:first-of-type {
        margin-top: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      .item-card {
        margin-left: 0;
        margin-right: 0;
      }

      .item-card:last-of-type {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }

  .disclaimer-text {
    font-style: italic;
    font-size: 16px;
    color: Constants.$text-on-light;
  }

  .address-section {
    font-size: 16px;

    .address-text {
      color: Constants.$text-on-light;
    }

    .change-text {
      cursor: pointer;
      text-decoration: underline;
      font-size: 16px;
      color: Constants.$clickable-text;
      display: inline;
      background: none;
      margin-left: 16px;
    }

    .set-address-button {
      --background: Constants.$btn-next-ready;
      background-color: Constants.$btn-next-ready;
      --border-radius: 0.75em;
      border-radius: 0.75em;
      width: 60%;
      margin: auto;
      margin-top: 6px;
      font-family: "Upheaval";
      font-size: 1.2em;
    }

    // used to overwrite default ionic styles
    div {
      font-size: 16px;
    }
  }

  .end {
    position: absolute;
    right: 0;
  }

  #confirm-btn {
    min-height: 50px;
    color: Constants.$text-on-light;
    --background: #{Constants.$neutral-gray};
    font-family: 'Upheaval';

    ion-checkbox {
      --border-color: rgba(255, 255, 255, 0);
      --border-color-checked: rgba(255, 255, 255, 0);
      --border-radius: 50%;
      --checkbox-background: #{Constants.$white};
      --checkbox-background-checked: #e75432;
    }
  }
}

#fight-under-review {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
  z-index: 2;

  img {
    width: auto;
    height: 60%;
    margin-top: 20%;
  }
}
