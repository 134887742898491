@use '../Constants.scss';

#reset-page {
  --background: #{Constants.$background-main};
  text-align: center;
  font-family: 'Rubik';

  .form-content {
    width: 75%;
  }

  .reset-password-header {
    margin-top: 1vh;
    position: relative;
    top: 60px;
    text-align: center;
    font-size: 24px;
    box-shadow: none;
  }

  .reset-password-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 5vh;
    // padding-top: 5vh;
    padding-top: calc(5vh + var(--ion-safe-area-top));
    background: #{Constants.$background-main};
    text-align: left;

    .reset-msg {
      text-align: center;
    }
  }

  #entry-options {
    width: 100%;
    margin: {
      left: auto;
      right: auto;
      top: 4vh;
    }

    p {
      text-align: left;
      font-size: 16px;
      color: Constants.$text-on-light;

      margin-bottom: 18px;

      &:not(:first-of-type) {
        margin-top: 0.75rem;
      }
    }

    .textarea-highlight {
      --highlight-color: #{Constants.$btn-red-orange};
    }
  }

  .verification-remark {
    font-weight: 500;
    margin-top: 0;
    font-size: 16px;
    line-height: calc(16 * 1.5px);
    text-align: center;
  }

  .data-input {
    border-radius: 28px;
    padding: 9px 30px;
    margin-bottom: 15px;
    min-height: 50px;
    &:focus {
      outline: none;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 50px;
  }

  .next-button {
    height: 50px;
    --background: #{Constants.$btn-red-orange};
    --background-activated: #{Constants.$btn-next-ready};
    --background-hover: #{Constants.$btn-next-ready};
    position: absolute;
    bottom: 0;
    width: 75%;
  }

  .back-to-homepage {
    position: absolute;
    left: 60px;
    font-size: 30px;
    // &:before {
    //   content: url('../../assets/icons/arrowLeftIcon.svg');
    //   cursor: pointer;
    // }
  }

  .label {
    color: #7a7a7a;
    font-size: 11px;
    padding-bottom: 5px;
  }

  .logo-icon {
    position: relative;
    bottom: 40px;
    &:before {
      content: url('../../assets/tmp_logo_icon.svg');
    }
  }
}
