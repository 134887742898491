@use '../Constants.scss';

.fight-card {
    --background: #{Constants.$white};
    --color: #{Constants.$text-on-light};
    border-radius: 1rem;
    margin: {
        top: 0;
        bottom: 1rem;
    };

    ion-card-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;


        .fight-outcome {
            position: absolute;
            left: 0;
            top: 0;
            width: 23%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                margin: 0;
            }
        }

        .win {
            background-color: Constants.$win-green;
            color: Constants.$text-on-dark;
        }

        .tie {
            background-color: Constants.$tie-yellow;
            color: Constants.$text-on-dark;
        }

        .loss {
            background-color: Constants.$loss-red;
            color: Constants.$text-on-dark;
        }
        .win, .tie, .loss {
            font-family: 'Upheaval';
            h1 {
                font-size: 23px;
                color: #fff;
            }
        }
    }
}

.items-trigger::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    margin-left: 5px;
    bottom: -3px;
    position: relative;
    top: -2px;
  }

.right-side-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 85%;
    justify-content: space-between;
    position: absolute;
    right: 5%;
    .sport {
        font-family: "Upheaval";
        font-size: 16px;
        border: 1px solid #D9D9D9;
        border-radius: 7px;
        padding: 5px;
        background: #D9D9D9;
        color: #000;
    }
    .date {
        padding-bottom: 5px;
    }
}

.user-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 7%;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    .bold {
        font-weight: bold;
    }
    .user-info-items {
        display: flex;
        .bold:nth-child(2) {
            position: relative;
            left: 5px;
        }
    }
}
