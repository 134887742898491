@use '../Constants.scss';

.edit-profile-page {
  --background: #{Constants.$background-main};
  --ion-background-color: #{Constants.$background-main};
  --ion-toolbar-background: #{Constants.$background-main};
  --background-hover: #fff;
  --background-hover-opacity: 0.8;
  --background-focused: #fff;
  --background-focused-opacity: 0.24;
  text-align: center;
  padding-top: var(--ion-safe-area-top);

  .back-button-icon {
    color: #{Constants.$dark-gray};
  }

  .page-title {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .profile-pic {
    height: 8vh;
    width: 8vh;
    border-radius: 50%;
    object-fit: cover;
  }

  .edit-photo {
    color: #{Constants.$clickable-text};
    cursor: pointer;
    text-decoration: underline;
    // margin: 0;
    // margin-top: 0.25rem;
    display: table;
    margin: 0.25rem auto 0 auto;
    font-size: 1rem;
  }

  .info-label {
    width: 75%;
    margin: {
      left: auto;
      right: auto;
    }
    text-align: left;
  }

  // [type="date"]::-webkit-inner-spin-button {
  //     display: none;
  // }

  // [type="date"]::-webkit-calendar-picker-indicator {
  //     display: none;
  // }

  .ios .entry-options .inputs-wrapper {
    .address-field-input,
    .autocomplete-input {
      height: 42px;

      &:focus {
        outline-style: none;
      }
    }
  }

  .entry-options {
    width: 75%;
    margin: {
      left: auto;
      right: auto;
    }

    .entry-label {
      text-align: left;
      font-size: 0.75rem;
      color: Constants.$text-on-light;

      margin-bottom: 0.25rem;

      &:not(:first-of-type) {
        margin-top: 0.75rem;
      }
    }

    input:read-only {
      color: Constants.$btn-next-disabled;
    }

    .inputs-wrapper {
      padding: 0;
      row-gap: 0.25rem;
      column-gap: 1%;
      --ion-padding: 0;
      --padding-top: 0;
      --padding-start: 0;

      .address-field-input {
        height: 56px;
        border-radius: 100px;
        padding: 0 32px;
        width: 48%;
        border-color: Constants.$black;
        text-align: center;
        margin: 0;

        &::placeholder {
          opacity: 0.35;
        }

        &:focus {
          outline-style: solid;
        }

        &.street-address-field-input {
          width: 97%;
        }
      }
    }

    // class name from GoogleAutocomplete.js
    .autocomplete-input {
      height: 56px;
      --background: #{Constants.$white};
      padding: 0 28px !important;
      text-align: left !important;

      font-size: 0.75rem;

      border: 1px solid #{Constants.$black};
      border-radius: 100px;
      color: #000;

      &::placeholder {
        opacity: 0.35;
      }

      &:focus {
        outline-style: solid;
      }

      // .sc-ion-input-ios {
      //     --background: #{Constants.$white};
      //     border-color: Constants.$black;
      //     --border-color: #{Constants.$black} !important;
      //     --placeholder-opacity: 0.35;
      //     text-align: left;
      // }
    }

    .submit-button {
      --background: #{Constants.$btn-red-orange};
      --background-hover: #{Constants.$btn-next-ready};
      --background-activated: #{Constants.$btn-next-ready};
      margin: 0.75rem 0;
    }
  }
}
