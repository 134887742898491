@use '../Constants.scss';

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-slide-in {
  animation: slideInFromBottom 0.5s ease-in-out forwards;
  transform: translateY(100%);
}

@keyframes slideOutToBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.modal-slide-out {
  animation: slideOutToBottom 0.5s ease-in-out forwards;
  transform: translateY(100%);
}

.content-wrapper {
  margin: 0 3%;
}

.details-modal-ion-content {
--ion-background-color: #{Constants.$background-main};
}

.close {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
}

.bet-details_header {
  padding-top: 50px;
}

.bet-details_font {
  font-family: 'Upheaval';
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 10px;
  padding-top: 30px;
}
.bet-details_font.bet-details_title {
  color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 21px;
}

.bet-details_modal_header_toolbar {
  --background: #e75432;
}

.bet-details_leftTeam,
.bet-details_rightTeam {
  color: #fff;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: calc(tan(var(--skew)) * var(--panel-height));
    height: 100%;
    transform-origin: bottom;
    transform: skew(calc(-1 * var(--skew)));
  }
  p {
    text-align: center;
  }
}

.gap {
  position: relative;
  width: var(--gap-width);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.panel-vs-circle {
  font-family: 'Upheaval';
  width: calc(var(--panel-height) / 2.5);
  height: calc(var(--panel-height) / 2.5);
  flex-shrink: 0;
  border-radius: 50%;
  z-index: 2;
  background-color: #f4dfc7;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  .bet-modal-circle {
    font-family: 'Upheaval';
    font-size: calc(var(--panel-height) / 4);
  }
}

.game-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  --panel-height: var(--game-panel-height, 75px);
  --gap-width: var(--game-panel-gap-width, 1%);
  --skew: var(--game-panel-skew, 20deg);
  --left-color: var(--game-panel-left-color, blue);
  --right-color: var(--game-panel-right-color, red);
  .bet-details_teams {
    width: 100%;
    height: var(--panel-height, 100px);
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  .team {
    width: calc(50% - var(--gap-width) / 2 - tan(var(--skew)) * var(--panel-height) / 2);
    height: var(--panel-height, 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      position: relative;
      font-size: 1.25rem;
      position: relative;
      // padding-bottom: 10px;
      margin: 0;
    }
    h1 {
      position: relative;
      // bottom: 30%;
      font-size: 2rem;
      z-index: 99;
      // padding-top: 10px;
      padding: 0;
      color: Constants.$text-on-dark;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: Constants.$border-for-text;
      margin: 0;
    }
  }
  .bet-details_leftTeam {
    background-color: var(--left-color);
    border-radius: calc(var(--panel-height) / 7.5);
    border-top-right-radius: 0;
    p {
      // top: 30%;
    }
  }
  .bet-details_leftTeam::before {
    background-color: var(--left-color);
    border-top-right-radius: calc(var(--panel-height) / 7.5);
    border-bottom-right-radius: calc(var(--panel-height) / 7.5);
    right: 0;
    content: '';
  }
  .bet-details_rightTeam {
    background-color: var(--right-color);
    border-radius: calc(var(--panel-height) / 7.5);
    border-bottom-left-radius: 0;
    p {
      // top: 30%;
    }
    &:before {
      background-color: var(--right-color);
      border-top-left-radius: calc(var(--panel-height) / 7.5);
      border-bottom-left-radius: calc(var(--panel-height) / 7.5);
      right: 100%;
      content: '';
    }
  }
  .gap {
    position: relative;
    width: var(--gap-width);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .panel-vs-circle {
      width: calc(var(--panel-height) / 2.5);
      height: calc(var(--panel-height) / 2.5);
      flex-shrink: 0;
      border-radius: 50%;
      z-index: 2;
      background-color: #f4dfc7;
      color: #4d4d4d;
      display: flex;
      justify-content: center;
      align-items: center;
      .bet-modal-circle {
        position: relative;
        bottom: 5px;
      }
    }
  }
}

.bet-details_list {
  padding-left: 5%;
  padding-right: 5%;
}

.bet-details_outcome {
  font-family: 'Upheaval';
  text-align: center;
  background: #ff451a;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.3rem;
}

.bet-details_list {
    margin: 0;
    li {
        background: #f4dfc7;
        border-radius: 20px;
        list-style: none;
        height: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-bottom: 5px;
        margin-top: 0;
        padding-left: 0;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        .fight-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-name {
                font-size: 14px;
                position: relative;
                left: 3%;
                max-width: 60%;
            }
            .item-price {
                font-size: 14px;
                position: absolute;
                right: 24%; 
            }
            .item-quantity {
                position: relative;
                right: 5%;
                background: #fff;
                border-radius: 8px;
                padding: 10px 15px;
            }
        }
    }
}

.fight-button {
  font-family: 'Upheaval';
  font-size: 1rem;
  --background: #e75432;
  height: 50px;
  width: 90%;
  margin: 40px auto 40px;
  box-shadow: 1px 4px 7.1px 0px rgba(0, 0, 0, 0.2);
  display: flex;
}

.fight-button .button-disabled {
  --background: #9f9f9f;
}

.match-challenger-button {
  --background: #d9d9d9;
  --background-activated: #ff451a;
  .match-challenger-button__text {
    color: #535353;
    font-size: 20px;
    right: 7%;
    position: relative;
  }
}

.disclaimer-text {
  font-style: italic;
  font-size: 16px;
  color: Constants.$text-on-light;
}

.end {
  --background-checked: var(--ion-color-primary);
  --border-radius: 50%;
  --size: 25px;
}

.pac-target-input,
.rest-modal-input {
  width: 100%;
  font-size: 1em;
  margin-top: 20px;
  text-align: left;
  border: 1px solid #e6e6e6;
  border-radius: 14px;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
}

.address-header {
  color: #30313d;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 0.93rem;
  line-height: 1.15;
  margin-bottom: 0.25rem;
}

.address-container {
  .past-addresses-text {
    text-align: center;
    margin: 10px auto 2px;
    display: table;
    position: relative;
  }

  .past-address {
    display: flex;
    --background: #{Constants.$background-past-address};
    --background-hover: #{Constants.$white};
    --background-hover-opacity: 0.8;
    --background-focused: #{Constants.$white};
    --background-focused-opacity: 0.24;
    --background-activated: #{Constants.$white};
    --background-activated-opacity: 0.24;
    color: #{Constants.$dark_gray_2};
    --box-shadow: 0px 5px 5px 0px #00000040;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    font-size: 0.8em;
    font-family: "Orbitron";
    font-weight: 500;
    // max-width: 10rem;
    padding: 8px 5%;
    margin: 0;
    width: 100%;
  }
}

#apt-number {
  margin-top: 0.75rem;
}

.error-message {
  color: red;
  font-size: 0.875em;
  margin-top: 4px;
}
