@use '../Constants.scss';

#profile-page {
    text-align: center;
    --background: #{Constants.$background-main};
    color: Constants.$text-on-dark;

    &::part(scroll) {
        display: flex;
        flex-direction: column;
    }

    #profile-info {

        #profile-info-top {
            justify-content: center;
            flex-direction: column;
            align-items: center;

            #profile-pic {
                height: 15vh;

                padding: {
                    left: 0.5rem;
                    right: 0.5rem;
                }
            }

            #profile-name {
                margin-top: 0.5rem;
                font-weight: bold;
                font-size: xx-large;
                font-family: 'Orbitron';
            }

            #profile-info-text {
                flex: 1 1 0;

                margin: {
                    top: auto;
                    bottom: auto;
                    right: 1rem;
                }

                h1 {
                    font-weight: bold;
                    margin: 0;
                }

                #fav-sports {
                    margin-top: 0.5rem;
                    justify-content: center;

                    .fav-sports-chip {
                        --background: #{Constants.$badge-sports};
                        --color: #{Constants.$text-on-light};
                    }
                }
            }
        }

        #bio {}
    }

    .tab-view {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;

        .tab-view-active-view {
            flex: 1 0 0;

            &>div {
                height: 100%;
            }
        }
    }

    #live-list {
        min-height: 100%;
        background-color: Constants.$background-profile-list;
        color: Constants.$text-on-light;

        h2 {
            text-align: left;
            font-weight: bold;
            margin-left: 1rem;
        }
    }

    .cols {
        justify-content: space-evenly;

        .col {
            p {
                margin: 0;
            }
        }
    }
}

.ion-page {
    background: #FAF7EC;
}

.profile-page-wrapper {
    // margin-top: 16px;
    .foodfight-icon {
        position: relative;
        // right: -0.1em;
    }
}

.foodfight-icon-profile {
    max-height: 30px;
    max-width: 60px;
    margin-top: 20px;
}