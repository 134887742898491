@use './Constants.scss';

.cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-justify-center {
    justify-content: center !important;
}

.flex-justify-end {
    justify-content: flex-end !important;
}

.flex-align-end {
    align-items: flex-end !important;
}

.flex-align-center {
    align-items: center !important;
}
