@use '../Constants.scss';
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tour-popup {
  background: white;
  color: #e75432;
  padding: 20px;
  border-radius: 8px;
  border: 3px solid #e75432;
  z-index: 1003;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  max-width: 80%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

.tour-description {
  margin: 20px 0px 45px 0px;
  font-size: x-large;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #10575f;
  color: #10575f;
  padding: 10px;
  border-radius: 4px;
  z-index: 10002;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.tooltip-arrow.tooltip-bottom {
  border-bottom: 8px solid #fff;
  top: -10px;
  transform: translateX(-50%);
}

.tooltip-arrow.tooltip-top {
  border-top: 8px solid #fff;
  bottom: -10px;
  transform: translateX(-50%);
}

.progress-dots {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  background-color: #ccc;
  border-radius: 50%;
}

.dot.active {
  background-color: #5f5f5f;
}

.skip-button {
  position: absolute;
  top: 5px;
  right: 0.7rem;
  color: #e75432;
  text-decoration: underline;
}

.next-product-button {
  position: absolute;
  bottom: 5px;
  right: 10px;
  --background: #e75432;
  --background-hover: #e75432;
  --background-activated: #e75432;
  --background-focused: #e75432;
  --color: #fff;
}

.prev-product-button {
  position: absolute;
  bottom: 5px;
  right: 4.5rem;
  --background: #e75432;
  --background-hover: #e75432;
  --background-activated: #e75432;
  --background-focused: #e75432;
  --color: #fff;

  &.firstStep {
    visibility: hidden;
  }
}

.hidden {
  visibility: hidden;
}

.border-overlay.cloned-element {
  z-index: 1005 !important;
  border: 3px solid #10575f;
  box-shadow: 0 0 10px #10575f;
  background: rgba(255, 255, 255, 1);
  pointer-events: none;
  position: absolute;
}

.border-overlay.cloned-element.referal-button {
  padding-bottom: 50px;
}

.referal-button:not(.border-overlay):not(.cloned-element) {
  padding-bottom: 0px;
}

#fight-button.cloned-element {
  justify-content: center; //
  align-items: center; //
  text-align: center;
  position: absolute;
  display: flex;
  .circle {
    position: absolute;
    display: flex;
    justify-content: center; //
    align-items: center; //
    text-align: center;
    flex-direction: column;
    height: 55px;
    width: 55px;
    background-color: #{Constants.$orange_red};
    border-radius: 50%;
    z-index: 1;
  }

  .outer-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 72px;
    width: 72px;
    background-color: #{Constants.$light_gray_3};
    border-radius: 50%;
  }

  .glove {
    height: 26.78px;
    width: 26.78px;
    padding-left: 10px;
  }

  .fight-label {
    height: 17px;
    width: 28px;
    right: 13px;
    top: 35px;

    font-size: 12px;
    font-style: normal;
    color: #{Constants.$white};
    line-height: 16px;

    letter-spacing: -0.4px;
  }
}

/*This is to deal with smaller phones - Surface Duo*/
@media only screen and (max-width: 540px) and (max-height: 720px) {
  .tour-description {
    margin: 20px 0px 30px 0px;
    font-size: medium;
  }

  .tour-popup {
    position: absolute;
    top: 68%;
  }

  .tooltip {
    font-size: small;
  }
}

/*This is to deal with smaller phones - SE */
@media only screen and (max-width: 400px) and (max-height: 750px) {
  .tour-description {
    margin: 20px 0px 30px 0px;
    font-size: medium;
  }

  .tour-popup {
    position: absolute;
    top: 62%;
  }

  .tooltip {
    font-size: small;
  }
}

/*This is to deal with wider phones - Nest*/
@media only screen and (min-width: 1000px) and (min-height: 600px) {
  .tour-popup {
    position: absolute;
    top: 32%;
  }
}
