@use '../Constants.scss';
#wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 22px;
  width: 100%;
  height: calc(22 * 3.5px);
  margin: {
    left: auto;
    right: auto;
    bottom: 3%;
  }

  #code-input {
    // border-bottom: 1px solid #{Constants.$text-on-light};
    background-color: white;
    border: 2px solid #ff451a;
    border-radius: 8px;
    text-align: center;
    margin: 4px;
    width: 48px;
    --highlight-color-focused: #ff451a;
  }
}
