@use '../Constants.scss';

.record-list {
  // overflow-y: auto;
  width: 100%;
  background-color: Constants.$background-profile-list;
  --background: Constants.$background-profile-list;
  padding-top: 1rem;
  --padding-top: 1rem;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .record-header {
    --background: #{Constants.$neutral-gray};
    border-radius: 2rem;
    box-shadow: 0 0.25rem 0.5rem;
    font-family: 'Upheaval';

    margin: {
      top: 0;
      bottom: 0;
    }

    .record-text {
      font-size: min(1.5rem, 5.6vw);
    }

    .record-card.ios .record-text {
      font-size: min(1.5rem, 5vw);
    }

    .record-card {
      font-weight: bold;

      padding: {
        top: 0.25rem;
        bottom: 0.25rem;
      }
    }
  }

  .fight-card {
    margin: {
      top: 1rem;
    }
  }
}