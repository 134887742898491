@use '../../styles/Constants.scss';
.history-toolbar {
  --background: #e75432;
  .history-title {
    color: #fff;
  }
}

.recent-chats {
  --background: #{Constants.$background-main};
}

.recent-chat-items {
  --background: #{Constants.$background-main};
  --min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  --inner-padding-end: 0px;
}

.recent-badge {
  --margin-left: 8px;
  --padding-end: 15px;
  --padding-start: 15px;
  --margin-bottom: 4px;
}

.bold-label {
  font-weight: 600;
}

.recent-chat-list {
  background: #{Constants.$background-main};
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.msg-time {
  font-size: 0.8em;
  color: gray;
}
