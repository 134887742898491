.custom-toast {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #ff451a;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 350px;

  .toast-logo {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }

  .toast-message {
    font-size: 1rem;
    font-weight: bold;
  }

  &.slide-in {
    animation: slideIn 0.4s ease forwards;
  }

  &.slide-out {
    animation: slideOut 0.4s ease forwards;
  }
}

@keyframes slideIn {
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes slideOut {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, -100%);
  }
}
