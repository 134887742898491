@use '../Constants.scss';
@use '../global.scss';

.matchmaking-card {
  display: flex;
  --background: #f4e0c7;
  box-shadow: none;
  border-radius: 2.5vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 25vw;
  position: relative;
  margin-bottom: 0px;
}

.orange-box {
  position: absolute;
  width: 15vw;
  height: 100%;
  background: #ff451a;
  border-radius: 0 12.5% 12.5% 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.glove-image {
  background: url('../../components/home/icon-park_boxing.svg') center center no-repeat;
  background-size: contain;
  background-position: center;
  width: 45%;
  height: 45%;
  position: absolute;
  bottom: 40%;
}

.IonRow {
  overflow: hidden;
}

.svg-container {
  margin-top: -1.5%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-svg {
  width: 40vw;
  height: auto;
  margin-left: -26.5vw;
}

.right-svg {
  width: 40vw;
  height: auto;
  margin-left: -13vw;
}

.left-svg text {
  font-family: 'Upheaval';
  transform: translateX(-1%) translateY(5%);
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.4px;
  fill: Constants.$text-on-color;
  stroke-width: 1px;
  stroke: Constants.$border-for-text;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.right-svg text {
  font-family: 'Upheaval';
  transform: translateX(-2%) translateY(5%);
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.4px;
  fill: Constants.$text-on-color;
  stroke-width: 1px;
  stroke: Constants.$border-for-text;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.vs-circle {
  font-family: 'Upheaval';
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.5vw;
  height: 6.5vw;
  background-color: #f4e0c7;
  border-radius: 50%;
  font-weight: bold;
  z-index: 1;
  margin: 0 2.8vw;
  left: calc(45% - 7.5vw - 3.25vw);
  top: 35%;
  transform: translate(-170%);

  color: Constants.$text-on-light;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5.5vw;
  letter-spacing: -0.4px;
}

.you-text {
  display: flex;
  font-weight: bold;
  color: #4d4d4d;
  position: absolute;
  font-size: 5vw;
  top: 70%;
  left: -35.5%;
  transform: translateX(-34%) translateY(-75%);
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  height: 4vw;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  border: Constants.$border-for-text 0.8px solid;
}

.other-text {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #4d4d4d;
  position: absolute;
  font-size: 5vw;
  top: 70%;
  transform: translateX(-15%) translateY(-75%);
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  height: 4vw;
  margin-top: 2px;
  border: Constants.$border-for-text 0.8px solid;
}

.team-img {
  position: absolute;
  top: -37.5%;
  right: 36%;
  width: 4vw;
  height: 4vw;
  transform: translateY(-50%);
}

.team-image {
  width: 3vw;
  height: 3vw;
}

.bet-details span:not(:last-child) {
  margin-right: 0.75vw;
}

.bet-details {
  color: #4d4d4d;
  position: absolute;
  font-size: 3.5vw;
  bottom: -18%;
  transform: translateY(-50%);
  right: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 8px 0px;
  border-radius: 8px;
  height: 14.7vw;
  width: 18vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.bet-details .items {
  display: flex;
  text-align: center;
  align-items: center;
}

.ion-padding {
  div {
    font-size: 2.5vw;
  }
}

.white-space {
  height: 100%;
  background: #fff;
  width: 1vw;
  position: absolute;
  right: 15%;
  bottom: 0%;
}

.bold-text-outcome {
  position: relative;
  top: 5px;
  margin-top: 0;
  font-weight: bold;
  font-size: 3.5vw;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15vw;
}

.text-items {
  font-size: 2vw;
  text-align: center;
  display: flex;
}

.no-fights-text {
  color: #a0a0a0;
  font-family: 'Upheaval';
  font-size: 5.5vw;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 18px;
  margin-left: 5%;
  margin-right: 5%;
}

.items::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-left: 5px;
  bottom: 2px;
  position: relative;
  top: 1px;
}

.loading-wrapper.sc-ion-loading-md {
  background: none;
  box-shadow: none;
}

ion-spinner.sc-ion-loading-md {
  width: 80px;
  height: 80px;
}

.time {
  color: #fff;
  position: absolute;
  font-size: 2.5vw;
  bottom: 21%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.hidden {
  visibility: hidden;
}

@media (max-width: 500px) {
  .right-svg,
  .left-svg {
    width: 38vw;
  }
  .you-text {
    left: -43%;
  }
  .bet-details {
    bottom: -16%;
    height: 14.4vw;
  }
}

@media (max-width: 650px) {
  .other-text,
  .you-text {
    font-size: 2vw;
    border-radius: 5px;
    padding: 3px 10px;
  }
}
