@use '../Constants.scss';

.overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 99999;

    &.blur {
        backdrop-filter: blur(5px);
    }

    .overlay-msg {
        width: 50%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 1.5rem;
        color: Constants.$white;
        background-color: Constants.$dark-gray;
        cursor: auto;
    }
}
