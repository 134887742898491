@use '../Constants.scss';

.apptabs-navbar {
  --ion-tab-bar-background: transparent;
  --ion-tab-bar-color: color-mix(in srgb, #{Constants.$text-on-light}, #{Constants.$white});
  --ion-tab-bar-color-selected: color-mix(in srgb, #{Constants.$text-on-light}, #{Constants.$black});

  background-color: Constants.$white;

  // XXX: will be useful down the line
  // ion-router-outlet {
  //     overflow: visible;

  //     .ion-page {
  //         overflow: visible;

  //         $overscroll: 100px;

  //         ion-content {

  //             &::part(background),
  //             &::part(scroll) {
  //                 padding-bottom: $overscroll;
  //                 margin-bottom: -$overscroll;
  //                 background-clip: padding-box;
  //             }
  //         }
  //     }
  // }

  ion-tab-bar {
    --border: none;
    contain: none;
    min-height: 75px;

    ion-tab-button {
      background-color: Constants.$white;

      &[tab='fight'] {
        // TODO: refactor later?
        .circle {
          position: absolute;
          display: flex;
          justify-content: center; //
          align-items: center; //
          text-align: center;
          flex-direction: column;
          height: 55px;
          width: 55px;
          background-color: #{Constants.$orange_red};
          border-radius: 50%;
          // right: 9px;
          // top: 10px;
          z-index: 1;

          &:hover {
            opacity: 50%;
            height: 60px;
            width: 60px;
          }
        }

        .outer-circle {
          // position: absolute;
          display: flex;
          justify-content: center; //
          align-items: center; //
          text-align: center;
          height: 72px;
          width: 72px;
          background-color: #{Constants.$light_gray_3};
          border-radius: 50%;
        }

        .glove {
          // position: absolute;
          height: 26.78px;
          width: 26.78px;
          // height: 100px;
          // right: 15px;
          // top: -25px;

          padding-left: 10px;
        }

        .fight-label {
          height: 17px;
          width: 28px;
          right: 13px;
          top: 35px;

          font-size: 12px;
          font-style: normal;
          color: #{Constants.$white};
          line-height: 16px;

          letter-spacing: -0.4px;
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}
