@use './Constants.scss';

@font-face {
    font-family: "8BIT WONDER";
    src: url("../assets/fonts/8-BITWONDER.TTF") format("truetype");
}

@font-face {
    font-family: 'Upheaval';
    src: url('../../public/upheaval/upheavtt.ttf') format('truetype');
}

@font-face {
    font-family: 'Orbitron';
    src: url('../assets/fonts/Orbitron/Orbitron-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    src: url('../assets/fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
}