// @use './Constants.scss';
$background-main: #FAF7EC;
$text-on-light: #4D4D4D;

#loading-page {
  ion-content::part(scroll) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // TODO: temporary because node-sass doesn't support modules
  --ion-background-color: #{$background-main};

  p {
    color: $text-on-light;
  }
}
