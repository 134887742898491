@use '../../Constants.scss';

@keyframes click {
  0% {
  }

  50% {
    scale: 0.975;
  }

  100% {
  }
}

@keyframes click-shadow {
  0% {
  }

  50% {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25) inset;
  }

  100% {
  }
}

.fighter-tile {
  position: relative;
  border: 1px solid Constants.$medium-gray;
  border-radius: 1rem;
  background-color: Constants.$light_gray_4;
  height: 100%;
  padding: 0.7rem;
  cursor: pointer;

  p {
    margin: 0;
    font-size: small;
    color: Constants.$text-on-light;
    white-space: pre;
  }

  .icon-img {
    width: 42px;
    height: 45px;
  }

  &.selected {
    background-color: Constants.$btn-red-orange;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25) inset;
    animation: click-shadow 0.5s;

    * {
      animation: click 0.5s;
    }

    p {
      color: Constants.$text-on-dark;
    }
  }
}

@media only screen and (max-height: 750px) {
  .fighter-tile .icon-img {
    width: 30px;
    height: 33px;
  }

  .fighter-tile {
    padding: 0.5rem;
  }
}

@media only screen and (max-height: 600px) {
  .fighter-tile .icon-img {
    width: 25px;
    height: 28px;
  }

  .fighter-tile {
    padding: 0.3rem;
  }
}
