@use '../Constants.scss';

.contact-us-modal {
  --background: #{Constants.$background-main};
}

.contact-us-modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background: #{Constants.$background-main};
  position: relative;
  text-align: center;

  .contact-title {
    position: absolute;
    top: 10vh;
    width: 100%;
    font-family: 'Upheaval';
    font-size: 2.5rem;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .contact-us-items {
    --background: #{Constants.$background-main};
    position: absolute;
    top: 19vh;
    --font-family: 'Upheaval';
    margin: 0 32px 32px 32px;
    --padding-start: 0px;
    width: 80%;
    --border-width: 0;
    --inner-border-width: 0;

    .contact-us-item {
      flex: 1;
      --background: #{Constants.$background-main};
      width: 100%;
      --border-width: 0;
      --inner-border-width: 0;

      .contact-us-label {
        font-size: 1.1rem;
        font-weight: bold;
        color: #{Constants.$black};
        width: fit-content;
        margin-left: 20px;
      }

      .contact-us-radio {
        --color-checked: #{Constants.$orange_2};
        margin: 0px;
        padding: 0px;
      }

      .contact-us-radio::part(container) {
        width: 25px;
        height: 25px;
        margin: 0px;
        padding: 0px;

        border-radius: 8px;
        border: 2px solid gray;
      }

      .contact-us-radio::part(mark) {
        background: none;
        transition: none;
        transform: none;
        border-radius: 0;
      }

      .contact-us-radio.radio-checked::part(container) {
        background: #{Constants.$orange_2};
        border-color: transparent;
      }

      .contact-us-radio.radio-checked::part(mark) {
        width: 6px;
        height: 10px;

        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #fff;

        transform: rotate(45deg);
      }
    }
  }

  .contact-us-item-msg {
    --background: #{Constants.$white};
    border: 2px solid #{Constants.$light_gray};
    border-radius: 14px;
    position: absolute;
    bottom: 20vh;
    width: 80%;
    margin: 0 32px;
    height: 40%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;

    .contact-us-textArea {
      padding: 10px;
      font-size: 1rem;
      color: #333;
      flex: 1;
      width: 100%;
      height: 50vh;
    }
  }

  .contact-us-buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
    color: #{Constants.$white};

    .contact-btn {
      --border-radius: 8px;
      font-weight: bold;
      --background: #{Constants.$btn-next-ready};
      --background-hover: #{Constants.$btn-red-orange};
      --background-activated: #{Constants.$btn-red-orange};
      color: #{Constants.$white};
      position: absolute;
    }

    .contact-close {
      right: 32px;
      font-size: 10px;
    }

    .contact-submit {
      width: 80%;
      height: 40px;
      font-size: medium;
      bottom: 10vh;
      margin: 0 32px;
    }
  }
}
