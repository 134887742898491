@use '../Constants.scss';

.friends-list-item {
    --ion-card-background: #{Constants.$background-friends-list-item};
    border-radius: 10px;
    display: flex;
    border: 2px solid;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 4rem;
    
    .button-wrapper {
        color: var(--color);
        background: none;
        font-size: 1em;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 3px;
        padding-right: 0;
        column-gap: 3%;

        // &:focus-visible {
        //     background-color: #{Constants.$medium-gray};
        //     color: #{Constants.$white};
        //     border-color: #{Constants.$btn-red-orange};
        // }
    
        .profile-pic {
            height: 4vh;
            width: 4vh;
            border-radius: 50%;
            object-fit: cover;
        }
    
        .username {
            font-size: 1.2em;
        }
    }

    .follow-button {
        --background: #{Constants.$btn-red-orange};
        --background-hover: #fff;
        --background-hover-opacity: 0.8;
        --background-focused: #fff;
        --background-focused-opacity: 0.24;
        --background-activated: #fff;
        --background-activated-opacity: 0.24;
        margin-left: 0;
        height: 4vh;
        width: 100px;
        font-weight: bold;

        &.is-following-true {
            --background: #{Constants.$btn-following};
            --color: #{Constants.$black};
            height: 100%;
        }
    }
}