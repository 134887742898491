@use '../../Constants.scss';

.match-modal {
  --height: auto;
  $border-radius: 1rem;
  --border-radius: #{$border-radius} #{$border-radius} 0 0;
  --ion-background-color: #{Constants.$background-main};
  --ion-toolbar-background: var(--ion-background-color);
  z-index: 900;

  h1 {
    text-align: center;
  }

  &.ios {
    // ios mode manually sets bottom radii to zero
    --border-radius: #{$border-radius};
  }

  ion-toolbar {
    ion-button {
      &.ios {
        // TODO: temporary fix
        width: 32px;
      }
    }

    ion-icon {
      cursor: pointer;
      color: Constants.$btn-red-orange;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: Constants.$white;
    }
  }

  .match-modal-content {
    width: 80%;
    padding: 0.5rem;
    justify-content: center;

    margin: {
      left: auto;
      right: auto;
    }

    p {
      text-align: center;
      margin: 20px;
    }

    ion-input {
      --border-color: #{Constants.$neutral-gray};
      margin-bottom: 1rem;

      label.sc-ion-input-ios {
        --border-radius: 4px;
        --padding-start: 16px;
        --padding-end: 16px;
        border-width: 1px;
        border-style: solid;
      }
    }

    ion-button {
      width: 60%;

      &.send {
        --background: #{Constants.$btn-next-ready};
        color: Constants.$white;
      }

      &.sent {
        color: Constants.$btn-next-ready;
        --border-color: #{Constants.$btn-next-ready};
      }

      &.button-disabled {
        // important that this is positioned last so it is given precedence
        --background: #{Constants.$btn-next-disabled};
      }

      margin: {
        left: auto;
        right: auto;
      }

      p {
        width: 100%;
      }
    }
  }
}
