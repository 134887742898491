@use '../../Constants.scss';

.team-outcomes {
  display: flex;
  flex-direction: row;
  background-color: #{Constants.$background-card};
  margin: 0 0.7em;
  padding: 0.5rem 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 3px 1px 10px Constants.$light_gray;
  border: none;
}

.teams-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .team.mma {
    font-size: 1em;
  }
}

.options-outcomes-container {
  display: flex;
}

.options-list {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  border-right: 1px solid Constants.$medium-gray;
  justify-content: center;

  .option:last-child {
    margin-bottom: 0;
  }
}

.option {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid Constants.$medium-gray;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: Constants.$light_gray_4;
  font-size: medium;
}

.option.selected {
  background-color: Constants.$btn-red-orange;
  border: 1px solid Constants.$btn-red-orange;
  color: Constants.$text-on-dark;
}

.option.empty {
  display: none;
}

#outcomes-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  flex: 1;
  margin: 1rem;
}

.outcome {
  padding: 2px;
  cursor: pointer;
  border: 1px solid Constants.$medium-gray;
  border-radius: 5px;
  background-color: Constants.$light_gray_4;
  p {
    font-size: small;
    text-align: center;
  }
  justify-content: center;
  align-content: center;
}

.outcome.active {
  background-color: Constants.$btn-red-orange;
  color: Constants.$text-on-dark;
}
