@use '../../Constants.scss';

.game-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  --panel-height: var(--game-panel-height, 150px);
  --piece-width: calc((50% - var(--gap-width) / 2 - $rhombus-width / 2));
  --gap-width: var(--game-panel-gap-width, 1%);
  --skew: var(--game-panel-skew, 20deg);
  --left-color: var(--game-panel-left-color, blue);
  --right-color: var(--game-panel-right-color, red);
  $rhombus-width: calc(tan(var(--skew)) * var(--panel-height));
  $border-radius: calc(var(--panel-height) / 7.5);

  .above,
  .below {
    width: 100%;
    height: auto;

    .gap {
      width: calc(var(--gap-width) + $rhombus-width);
    }

    & > *:not(.gap) {
      flex: 1 1 0;
    }
  }

  .below {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: small;
    font-family: 'Upheaval';
    margin: 0.2rem 0 0;
    .your-pick-left {
      grid-column: 1;
      margin: 0;
    }
    .your-pick-right {
      grid-column: 2;
      margin: 0;
    }
  }

  .panel {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .above {
      display: flex;
      flex-direction: row;
    }

    .gap {
      position: relative;
      width: var(--gap-width);
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 5px;
      align-items: center;
      align-self: center;

      .panel-vs-circle {
        width: calc(var(--panel-height) / 2.5);
        height: calc(var(--panel-height) / 2.5);
        flex-shrink: 0;
        border-radius: 50%;
        z-index: 2;
        background-color: Constants.$background-card;
        color: Constants.$text-on-light;
        display: flex;
        justify-content: center;
        align-items: center;

        * {
          margin: 0;
        }

        h1 {
          font-family: 'Upheaval';
          font-size: calc(var(--panel-height) / 4);
        }
      }
    }

    .piece {
      width: calc((50% - var(--gap-width) / 2 - $rhombus-width / 2));
      height: var(--panel-height);
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      * {
        position: relative;
        z-index: 1;
        margin: 0;
      }

      &.short > h1 {
        white-space: nowrap;
        height: auto;
        text-overflow: ellipsis;
      }

      &.leftSelectedPanel {
        // border: 5px solid #ffe000; //TODO: make constant
        width: calc((50% - var(--gap-width) / 2 - $rhombus-width / 2) + 5px);
        height: calc(var(--panel-height) + 5px);
        border-top: 5px solid Constants.$btn-red-orange;
        border-left: 5px solid Constants.$btn-red-orange;
        border-bottom: 5px solid Constants.$btn-red-orange;

        &::before {
          border-top: 5px solid Constants.$btn-red-orange;
          border-right: 5px solid Constants.$btn-red-orange;
          border-bottom: 5px solid Constants.$btn-red-orange;
        }
      }

      &.rightSelectedPanel {
        // border: 5px solid #ffe000; //TODO: make constant
        width: calc((50% - var(--gap-width) / 2 - $rhombus-width / 2) + 5px);
        height: calc(var(--panel-height) + 5px);
        border-top: 5px solid Constants.$btn-red-orange;
        border-right: 5px solid Constants.$btn-red-orange;
        border-bottom: 5px solid Constants.$btn-red-orange;

        &::before {
          border-top: 5px solid Constants.$btn-red-orange;
          border-left: 5px solid Constants.$btn-red-orange;
          border-bottom: 5px solid Constants.$btn-red-orange;
        }
      }

      &.otherSelectedPanel {
        height: calc(var(--panel-height) - 5px);
        width: calc((50% - var(--gap-width) / 2 - $rhombus-width / 2) - 10px);
        justify-content: center;
        justify-self: center;
        align-content: center;
      }

      p {
        width: 100%;
        color: Constants.$text-on-dark;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1.25rem;
      }

      h1 {
        width: 90%;
        font-family: 'Upheaval';
        font-size: 2rem;
        max-height: 2.5em;
        color: Constants.$text-on-color;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: Constants.$border-for-text;
        overflow: hidden;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: $rhombus-width;
        height: 100%;
        transform-origin: bottom;
        transform: skew(calc(-1 * var(--skew)));
      }

      &.left {
        $color: var(--left-color);
        background-color: $color;
        border-radius: $border-radius;
        border-top-right-radius: 0;

        &::before {
          background-color: $color;
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          right: 0;
        }
      }

      &.right {
        $color: var(--right-color); // TODO: what color should these be?
        background-color: $color;
        border-radius: $border-radius;
        border-bottom-left-radius: 0;

        &::before {
          background-color: $color;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          right: 100%;
        }
      }
      &.mma {
        @media (max-width: 800px) {
          h1 {
            font-size: 4vw;
          }
        }
      }
    }
    .piece.full-width {
      width: 100%;
    }
  }
}
