@use '../Constants.scss';

.friends-list-page {
    padding-top: var(--ion-safe-area-top);
    --ion-toolbar-background: #{Constants.$background-main};
    --ion-background-color: #{Constants.$background-main};

    .back-button {
        --color: #{Constants.$dark-gray};
    }
}