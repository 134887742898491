@use '../Constants.scss';

#signup-page {
  --background: #{Constants.$background-main};
  text-align: center;
  font-family: 'Rubik';

  #signup-content {
    margin-top: 6vh;

    .signup-header {
      width: 75%;
      margin: auto auto 20px auto;
      position: relative;

      .signup-back-button {
        position: absolute;
        left: 0;
        // height: 7.25vh;

        .signup-back-icon {
          color: #{Constants.$dark-gray};
        }
      }
    }

    #logo-icon {
      height: 7.25vh;
    }

    h2 {
      margin-top: 1vh;
    }

    .entry-field,
    .entry-field label {
      --border-radius: 12px;
      --border-color: #{Constants.$dark_gray_2};
      --border-width: 2px;
      background-color: #{Constants.$white};
      font-size: 16px;
      margin-bottom: 18px;
      text-align: center;
    }

    #entry-options {
      width: 75%;
      margin: {
        left: auto;
        right: auto;
        top: 4vh;
      }

      p {
        text-align: left;
        font-size: 16px;
        color: Constants.$text-on-light;

        margin-bottom: 18px;

        &:not(:first-of-type) {
          margin-top: 0.75rem;
        }
      }

      #resend-btn {
        text-decoration: underline;
        color: #{Constants.$btn-red-orange};
        cursor: pointer;
      }

      #signup-btn {
        --background: #{Constants.$btn-red-orange};
        --border-radius: 14px;
        --border-width: 2px;
        --border-color: #{Constants.$gray};
        --background: #{Constants.$btn-red-orange};
        --background-activated: #{Constants.$gloves_yellow};
        --color: #{Constants.$white};
        font-family: 'Upheaval';
        margin-top: 1vh;
        opacity: 1;
      }

      #agreements {
        text-align: left;
        color: Constants.$text-on-light;
        font-size: 0.75rem;
        display: flex;
        flex-direction: column;

        // XXX: maybe make a global property?
        a {
          color: blue;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }

        margin: {
          top: 1.25rem;
          bottom: 1.25rem;
        }

        div {
          display: flex;
          flex-direction: row;
          align-items: center;

          ion-label {
            margin-left: 0.25rem;
          }

          ion-checkbox {
            --border-width: 0.1rem;
            --border-radius: 0.25rem;

            &:not(:last-of-type) {
              margin-bottom: 0.75vh;
            }
          }
        }
      }
    }
  }
}

.password-req {
  font-size: 0.75rem;
  color: #{Constants.$gray};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.password-req ul {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.password-req .item {
  padding-left: 10px;
  margin-bottom: 5px;
  color: #{Constants.$req-needed};
}

.password-req .item::marker {
  content: none;
}

.password-req .green {
  color: #{Constants.$req-met};
}

.password-req .item::before {
  content: url('../../assets/passwordcheck.svg');
  position: relative;
  top: 1px;
  right: 10px;
}

.password-req .green::before {
  content: url('../../assets/password_green_check.svg');
  position: relative;
  top: 1px;
  right: 10px;
}

.verification-remark {
  font-weight: 500;
  margin-top: 8vh;
  font-size: 16px;
  line-height: calc(16 * 1.5px);
  text-align: center;
}
.code-btn-wrapper {
  margin-top: 58px;
  padding: 0 36px 0 36px;
  .verify {
    font-family: 'Upheaval';
    --background: #{Constants.$btn-red-orange};
    --border-radius: 14px;
    --border-width: 2px;
    --border-color: #{Constants.$gray};
    --background: #{Constants.$btn-red-orange};
    --background-activated: #{Constants.$gloves_yellow};
    --color: #{Constants.$white};
    opacity: 1;
    .button-native {
      --border-color: #{Constants.$gray};
    }
  }
  .resend-code-btn {
    font-family: 'Upheaval';
    --background: #{Constants.$gloves_yellow};
    --border-radius: 14px;
    --border-width: 2px;
    --border-color: #{Constants.$gray};
    --background-activated: #{Constants.$gloves_yellow};
    --color: #{Constants.$white};
    margin-top: 20px;
    opacity: 1;
    .button-native {
      --border-color: #{Constants.$gray};
    }
  }
}
.question-wrapper {
  position: relative;
  bottom: 1rem;
  margin: 15px 0;
  font-size: 12px;
  line-height: calc(12 * 1.25px);
  a {
    color: #{Constants.$black};
    font-weight: bold;
  }
}
