@use '../Constants.scss';

.match-card {
  --game-panel-height: 52px;
  --game-panel-skew: 25deg;
  --background: #{Constants.$background-card};
  border-radius: 10px;
  text-align: center;

  padding: 0.5em 0.75em;

  &::part(native) {
    display: flex;
    flex-direction: row;
    align-items: top;
  }

  & > *:not(:first-child):not(:last-child) {
    margin: 0 0.75em;
  }

  .game-panel {
    flex: 1 0 0;
    font-size: 1.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .datetime-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    height: var(--game-panel-height);
    // padding: 0.5em;
    border-radius: 0.5em;
    background-color: Constants.$white;
    text-align: center;

    p,
    h2 {
      margin: 0;
    }

    h2 {
      font-size: 0.8em;
      font-weight: bold;
      color: color-mix(in srgb, Constants.$text-on-light 90%, Constants.$black 10%);
    }

    p {
      font-size: 0.75em;
    }
  }

  .fight-btn {
    --padding-start: 0;
    --padding-end: 0;
    --background: #{Constants.$btn-red-orange};
    --background-hover: #{Constants.$btn-next-ready};
    --background-activated: #{Constants.$btn-next-ready};

    width: calc(var(--game-panel-height) - 0.75em);
    height: calc(var(--game-panel-height) - 0.75em);
  }
}
