@use '../../Constants.scss';

.share-modal {
    --height: auto;
    $border-radius: 1rem;
    --border-radius: #{$border-radius} #{$border-radius} 0 0;
    --ion-background-color: #{Constants.$background-main};
    --ion-toolbar-background: var(--ion-background-color);
    
    h1 {
        text-align: center;
    }

    &.ios {
        // ios mode manually sets bottom radii to zero
        --border-radius: #{$border-radius};
    }
    
    ion-toolbar {
        ion-button {
            &.ios {
                // TODO: temporary fix
                width: 32px;
            }
        }

        ion-icon {
            font-size: 25px;
            color: #fff;
            font-weight: bold;
        }
    }


    .share-modal-content {
        width: 80%;
        padding: 0.5rem;

        margin: {
            left: auto;
            right: auto;
        }

        p {
            margin: 0;
        }

        ion-input {
            --border-color: #{Constants.$neutral-gray};
            margin-bottom: 1rem;
            --background: #{Constants.$white};
            text-align: center;
            
            label.sc-ion-input-ios {
                --border-radius: 4px;
                --padding-start: 16px;
                --padding-end: 16px;
                border-width: 1px;
                border-style: solid;
            }
        }

        ion-button {
            width: 60%;

            &.send {
                --background: #{Constants.$btn-next-ready};
                color: Constants.$white;
            }

            &.sent {
                color: Constants.$btn-next-ready;
                --border-color: #{Constants.$btn-next-ready};
            }

            &.button-disabled {
                // important that this is positioned last so it is given precedence
                --background: #{Constants.$btn-next-disabled};
            }
            
            margin: {
                left: auto;
                right: auto;
            }

            p {
                width: 100%;
            }
        }
    }
}
