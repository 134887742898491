/* @use '../Constants.scss'; */

.home-content {
  --ion-background-color: #faf7ec;
}

#home-toolbar {
  --background: #eb5529;
}

#logo-col {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100;
}

#notification-col {
  margin: auto;
}

#menu-button {
  color: #f5f3cc;
}

#header-buttons {
  margin: auto;
  text-align: end;
}

#header-segment {
  border-width: 1.5px;
  border-color: #b7310a;
  border-style: solid;
}

#header-segment-button {
  --background: #f5f3cc;
  --color: #eb5529;
  --background-checked: #eb5529;
  --color-checked: #f5f3cc;
}

.image-menu-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.my-grid {
  position: relative;
}

.homepage__carousel {
  margin-inline: 1px;
  --background: #faf7ec;
  box-shadow: none;
  margin: 0;
}

.homepage__carousel__img {
  border-radius: 8px;
  /* width: 155px; */
  /* height: 100px; */
  flex-shrink: 0;
}

.homepage__carousel__title {
  color: #5e5e5e;
  /* font-family: SF Compact; */
  font-size: 14px;
  font-style: normal;
  font-weight: 656;
  line-height: 17px;
  letter-spacing: -0.2px;
  text-align: left;
  /* padding-bottom: 10px; */
  padding: 0;
}

.homepage__carousel__content {
  color: #5e5e5e;
  /* font-family: SF Compact; */
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: -0.4px;
  padding: 0;
  text-align: left;
}

#carousel-card-header {
  padding-left: 0px;
  padding-bottom: 1px;
  padding-top: 5px;
}

.popup-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.popup-rectangle {
  width: 370px;
  height: 50px;
  opacity: 0.800000011920929;
  background: linear-gradient(0deg, #e12a00 0%, #e12a00 100%), #d73e1b;
  border-radius: 8px;
  overflow: visible;
  position: relative;
}

.popup-image-container {
  position: absolute;
  top: -25px;
  left: 14px;
  margin-right: 10px;
}

#popup-image {
  display: block;
  width: 77px;
  height: 86px;
  object-fit: cover;
}

.popup-text-container {
  display: flex;
  justify-content: flex-end;
  height: 100% !important;
  padding: 0px;
  position: relative;
}

.popup-text-score {
  position: absolute;
  top: -27%;
  left: 40%;
  margin: 0;
}

.popup-text-paragraph {
  margin: 0;
  position: absolute;
  top: 35%;
  left: 33%;
  color: #fff;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-family: 'Upheaval';

  font-size: 19px;
  font-style: normal;
  font-weight: 457;
  line-height: 16px;
  letter-spacing: -0.4px;
}

#comma {
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-family: 'Upheaval';

  font-size: 20px;
  font-style: normal;
  font-weight: 457;
  line-height: 16px;
  letter-spacing: -0.4px;
}

.popup-text-team {
  color: #ffffff;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-size: 20px;
  font-style: normal;
  font-weight: 457;
  line-height: 28px;
  letter-spacing: -0.4px;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
}

.popup-text-score-numbers {
  color: #fff;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-size: 22px;
  font-style: normal;
  font-weight: 790;
  line-height: 28px;
  letter-spacing: -0.4px;
  display: inline-block;
}

.header-padding {
  margin-top: 150px;
}

.matchmaking-stream-segment {
  border-bottom: 0.75px solid #c7c7c5;
  /* position: relative; */
}
.matchmaking-stream-segment-button {
  --color: #a0a0a0;

  --color-checked: #5f5f5f;

  --indicator-color: #ff451a;

  --indicator-height: 5px;
  /* font-family: SF Compact; */
  text-align: center;
  font-family: 'Upheaval';
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-size: 20px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.4px;
  text-transform: none;
  letter-spacing: 0.5px;

  /* position: relative; 
    z-index: 2;  */
}

.square-image-container {
  width: 100%;
  /* padding-top: 100%; */
  height: auto;
  overflow: hidden;

  /* object-fit: hidden;  */
  /* flex: 0 0 70px; */
  padding: 0px;
  /* align-items: center; */
}

.square-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  /* padding-top: 5px; */
}
.matchmaking-stream-card {
  display: flex;
  --background: #f4e0c7;
  box-shadow: none;
  border-radius: 10px;
  padding: 0px;
  /* height: 10%; */
  /* align-items: center; */
  position: relative;
  max-height: 563px;
}
.stream-name {
  color: #4d4d4d;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: '8BIT WONDER';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 220% */
  letter-spacing: -0.4px;
  /* margin-bottom: 0px; */
  position: absolute;
  top: 0%;
}
.stream-text {
  color: #4d4d4d;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 22px; 157.143% */
  letter-spacing: -0.4px;
  /* margin-top: 0px; */
  position: absolute;
  top: 14%;
}
.bold-text {
  color: #4d4d4d;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* font-family: SF Compact; */
  font-size: 14px;
  font-style: normal;
  font-weight: 790;
  /* line-height: 22px; 157.143% */
  letter-spacing: -0.4px;
  /* margin-top: 0px; */
}
.subtitle-container {
  white-space: nowrap;
  /* width: 90%; */
  overflow: scroll;
  text-overflow: ellipsis;
}
/* .horizontal-scroll{

    white-space: nowrap;
    overflow-x: auto;

} */
.stream-buttons {
  position: absolute;
  top: 46%;
  /* left: -6%; */
  align-items: center;
  padding-left: 0px;
  /* padding-bottom: 0px; */
}
.stream-buttons img {
  margin-right: 9px;
  margin-left: 15px;
}
.stream-buttons img:first-child {
  margin-left: 0;
}
.image-col {
  padding: 1px;
}

.pay-with-food {
  position: relative;
  left: -30px;
}

@media (min-width: 740px) {
  .homepage__carousel__title {
    font-size: 20px;
  }
  .homepage__carousel__content {
    font-size: 15px;
  }
  .matchmaking-stream-segment-button {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
  .homepage__carousel__title {
    font-size: 30px;
    /* padding-bottom: 5px;
    padding-top: 5px; */
    padding: 0;
    line-height: 0.7;
  }
  .homepage__carousel__content {
    font-size: 22px;
    /* padding-bottom: 5px;
    padding-top: 5px; */
    padding: 0;
  }
  .matchmaking-stream-segment-button {
    font-size: 30px;
  }
}

.search-wrap {
  display: flex;
  padding: 0 3%;
}

.search-wrap .search-icon-img {
  max-width: 30px;
}

.search-list {
  z-index: 99;
  width: 100%;
  padding-bottom: 0;
}

.search-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchbar-input.sc-ion-searchbar-md,
.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  border-radius: 30px;
  background: #fff;
  font-style: italic;
}

.referal-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.referal-buttons-wrapper ion-icon {
  margin-inline-end: 8px;
}

.referral-link-display {
  display: none;
  text-align: center;
  user-select: text;
}

.restaurant-icon {
  margin-right: 8px;
  margin-left: 8px;
  border: 2px solid #000;
  --background: #e2e2e2;
  --border-color: #000;
  border-radius: 8px;
  min-width: 170px;
  max-height: 48px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

.restaurant-icon .item-native {
  --background: #fff;
  padding-left: 0;
  padding-right: 5px;
  align-items: center;
}

.referal-button {
  margin-right: 8px;
  margin-left: 8px;
  --background: #fee000;
  --color: #5e5e5e;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 2px;
  min-width: 170px;
  max-height: 48px;
  height: 48px;
  --border-radius: 8px;
  position: relative;
  --background-activated: #e6532f;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

.referal-button img {
  margin-right: 10px;
}

.venu-text {
  /* font-family: 'Upheaval'; */
  font-weight: bold;
  font-size: 0.9rem;
  margin: auto;
  margin-left: 0;
}

.fallback-message {
  background-color: #faf7ec;
}

.fallback-message > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf7ec;
  margin: 10px 0;
  padding: 0 10px;
}

.fallback-message div p {
  text-align: center;
  background-color: #faf7ec;
  padding: 0 20px;
}

.foodfight-icon {
  width: 15%;
  /* height: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 10px; */
}

/* Prevent modal from resizing to 100% when screen height goes below 600px on desktop */
@media only screen and (min-width: 1200px) {
  .img-modal {
    --width: 600px;
    --height: 500px;
  }
}

.hidden {
  visibility: hidden;
}
