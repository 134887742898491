@use '../../styles/Constants.scss';

.recent-modal {
  ion-header {
    ion-toolbar {
      display: flex;
      justify-content: space-between;
      --background: #{Constants.$btn-next-ready};
      padding: 0.5rem;
    }

    ion-title {
      font-family: 'Upheaval';
      color: #{Constants.$white};
      font-size: 1.5em;
      font-weight: 200;
      flex-grow: 1;
    }
  }
}

.recent-chats-close-button {
  --background-activated: #e75432;
  --background: #e75432;
}

.chat-content {
  --padding-bottom: 75px;
  height: 100%;
  overflow-y: scroll;
  --ion-background-color: #faf7ec;
}

.chat-title {
  font-family: 'Upheaval';
  color: #{Constants.$white};
  font-size: 1.5em;
  font-weight: 200;
}

.close-button,
.recent-chats-button,
.recent-close-button {
  box-shadow: none;
  --background-activated: #e75432;
  --background: #e75432;
}

.recent-close-button {
  top: 0px;
  font-size: 15px;
}

.close-button {
  top: auto;
  right: auto;
  font-size: 20px;
}

.chat-input-wrapper {
  display: flex;
  .chat-input {
    width: 100%;
    border: none;
    padding-left: 20px;
    border-radius: 20px;
    .input-wrapper {
      padding-left: 15px;
    }
  }
}

.chat-content-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  .chat-input-wrapper {
    --background: #faf7ec;
    --border-width: 0 !important;
  }
}

.footer-chat-wrapper {
  display: flex;
  margin: 1rem 2rem;
  border-radius: 20px;
  border: 1px solid #ebebeb;
  background: #fff;
}

.chat-send-button {
  --background: none;
  display: flex;
  align-items: center;
  font-size: 0;
  padding-right: 20px;
  &:before {
    content: url('./plane.svg');
  }
}

.chat-send-button ion-active {
  --background: none;
}
