@use '../Constants.scss';

.universal-header .search-wrap {
  --ion-toolbar-background: #{Constants.$background-main};

  // display: flex;
  position: relative;
  padding: var(--ion-safe-area-top) 2% 0;
  // right: 10px;

  &.ios {
    // workaround for profile page toolbar being a different size on ios
    height: calc(52px + var(--ion-safe-area-top));

    .header-buttons {
      margin: auto;
    }
  }

  .icon-wrapper {
    width: 13%;
    margin: {
      top: auto;
      bottom: auto;
    }
    flex-shrink: 0;

    .foodfight-icon {
      width: 42px;
      height: 42px;
      cursor: pointer;

      margin: 0;
    }
  }

  .header-buttons {
    margin-left: auto;

    .notification {
      background: #{Constants.$white_gray};
      border-radius: 30px;
      margin-left: 0.8rem;
    }
  }

  .header-search {
    --color: #{Constants.$text-on-light};
    --icon-color: #{Constants.$text-on-light};
    --background: #{Constants.$background-header-search};
    --border-radius: 2rem;

    padding: {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .searchbar-input {
      padding-left: 15%;
      color: rgb(38, 38, 38);
    }

    // Commented out the next few lines to look more like previous search bar
    // Left them in cause they seem WIP
    // TODO: see how this looks in native app
    // .searchbar-input-container>input {
    //     border: 1px solid Constants.$neutral-gray; // TODO: color subject to change
    // }

    // --box-shadow: none;

    // max-width: 300px; // XXX: for desktop where header will be very wide
  }
}
